import React, { useEffect, useState } from "react";
import "../dashboard.css";
import { Row, Form, Image, Breadcrumb, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import { Alert } from "react-bootstrap";
import deleteIcon from "../assets/icons/delete.png";
import uploadIcon from "../assets/icons/upload.png";
import { Table, Nav } from "react-bootstrap";
import editIcon from "../assets/icons/edit.png";
import {
  validateCreateReportForm,
  validateCreateReportFormForEdit,
} from "../request/validationFunctions";
import ReportService from "../services/reportService";
import researchDataService from "../services/researchDataService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams, useNavigate } from "react-router-dom";
import productService from "../services/productService";
import backIcon from "../assets/backIcon.png";
import LogoutAndRedirect from "./LogoutAndRedirect";
import Spinner from "react-bootstrap/Spinner";
const AddReport = () => {
  const navigate = useNavigate();
  // State to manage form data
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [products, setProducts] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);
  const [errors, setErrors] = useState({});
  const maxSize = 10 * 1024 * 1024; // 10 MB
  const [productsList, setProductsList] = useState([]);
  const [formData, setFormData] = useState({
    reportID: "",
    reportName: "",
    reportDescription: "",
    sampleImage: null,
    workspaceID: "",
    tenantID: "",
    status: "Active",
    createdBy: localStorage.getItem("email"),
    products: [""],
    productTableName: "",
    productTableColumnName: "",
    productTableFilterOperator: "Contains",
    productVisualName: "",
    createdAt: new Date().getTime(),
    updatedAt: new Date().getTime(),
    updatedBy: localStorage.getItem("email"),
  });
  const [initialFormData, setInitialFormData] = useState(formData);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [loader, setLoader] = useState(false);
  const [alreadyExistProd, setAlreadyExistProd] = useState(false);
  const { id } = useParams("id");

  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [edit, setEdit] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    const newErrors = {...errors};
    delete newErrors.productName;
    delete newErrors.productDescription;
  };

  const handleSave = () => {
    // Add your save logic here
    setIsEditable(false); // Disable editing after save
    setIsSaved(true); // Indicate that the form is saved
    setEdit(false);
  };

  const handleAddFilterClick = () => {
    setIsFilterExpanded(true);
  };

  const handleCancelFilterClick = () => {
    setIsFilterExpanded(false);
    setFormData({
      ...formData,
      productTableColumnName: "",
      productTableName: "",
      productVisualName: "",
      productTableFilterOperator: "Contains",
      products: [],
    });
    setProducts([]);
    setProductsList([]);
    setEdit(true);
    setIsSaved(false);
    const newErrors = {...errors};
    delete newErrors.productTableName;
    delete newErrors.productTableColumnName;
    delete newErrors.products;
    delete newErrors.productVisualName;
    setErrors(newErrors);
  };

  useEffect(() => {
    setInitialFormData(formData);
    const controller = new AbortController();
    if (id) {
      fetchData(controller.signal);
    } else {
      setInitialFormData(formData);
    }

    return () => controller.abort(); // Cleanup on unmount
  }, []);

  const fetchData = async (signal) => {
    if (id) {
      await ReportService.getReportById({ id: id })
        .then(async (res) => {
          if (res.data) {
            setFormData({
              id: res.data.id,
              reportID: res.data.reportID || "",
              reportName: res.data.reportName || "",
              reportDescription: res.data.reportDescription || "",
              sampleImage: res.data.sampleImage || null,
              workspaceID: res.data.workspaceID || "",
              tenantID: res.data.tenantID || "",
              status: res.data.status || "Active",
              createdBy: res.data.createdBy || localStorage.getItem("email"),
              products:
                res.data.productIds.length > 0 ? res.data.productIds : [""],
              createdAt: res.data.createdAt || new Date().getTime(),
              updatedAt: res.data.updatedAt || new Date().getTime(),
              updatedBy: res.data.updatedBy || localStorage.getItem("email"),
              productTableName: res.data.productTableName || "",
              productTableColumnName: res.data.productTableColumnName || "",
              productTableFilterOperator:
                res.data.productTableFilterOperator || "Contains",
              productVisualName: res.data.productVisualName || "",
            });
            if (res.data.productIds.length > 0) {
              setIsFilterExpanded(true);
              setEdit(false);
              setIsSaved(true);
              for (const product of res.data.productIds) {
                try {
                  const productRes = await productService.getProductById(
                    product
                  );
                  if (productRes.statusCode === 200) {
                    setProductsList((prevData) => {
                      // Check if the product already exists by comparing ids
                      const productExists = prevData.some(
                        (existingProduct) =>
                          existingProduct.id === productRes.data.id
                      );
                      // If product doesn't exist, add it to the list
                      if (!productExists) {
                        return [...prevData, productRes.data];
                      }
                      // Return the previous data if product already exists
                      return prevData;
                    });
                  }
                } catch (error) {
                  console.error("Error fetching product by ID:", error);
                }
              }
            }
          }
        })
        .catch((error) => {});
    }
    //setIsSaveDisabled(true)
  };

  useEffect(() => {
    // setIsExpanded(true);
    setProducts(productsList);
  }, [productsList]);

  const validateProduct = () => {
    let formIsValid = true;
    let newErrors = { productName: "", productDescription: "" };

    if (productName.trim() === "" && productName.length > 100) {
      newErrors.productName = "Filter name must not exceed 100 characters.";
      formIsValid = false;
    } else if (productName.trim() === "") {
      newErrors.productName = "Filter name is required.";
      formIsValid = false;
    }

    if (productDescription.trim() === "" && productDescription.length > 300) {
      newErrors.productDescription = "Filter description must not exceed 300 characters."
    } else if (productDescription.trim() === "") {
      newErrors.productDescription = "Filter description is required.";
      formIsValid = false;
    }

    setErrors(newErrors);
    return formIsValid;
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.target.files[0]; // Get the first file dropped
    if (file.size > 4194304) { // 4MB
      setError(true);
      setErrors({
        ...errors,
        image: 'File size should be less than 4MB'
      });
      setError(true);
    } else {
      setError(false);
      setErrors({
        ...errors,
        image: ''
      });
    }

    const fileExtension = file.name.split('.').pop().toLowerCase();
    const validExtensions = ['png', 'jpg', 'jpeg', 'gif'];
  
    if (!validExtensions.includes(fileExtension)) {
      setError(true);
      setErrors({
        ...errors,
        image: "Invalid file type. Please upload an image (PNG, JPG, JPEG, GIF).",
      });
      setError(true);
    } else {
      setError(false);
      setErrors({
        ...errors,
        image: ''
      });
    }
    handleFile(file);
  };
  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    handleFile(file);
  };
  const handleFile = (file) => {
    setImage(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  
  const getLastElement = (str) => {
    const parts = str.split(".");
    return parts.pop();
  };

  const handleDownload = async (sampleImage) => {
    //const sampleImage = sampleImage;
    // e.preventDefault();
    try {
      console.log("in download", sampleImage);
      const extension = getLastElement(sampleImage);
      // Make an API call to download the file as a blob
      await researchDataService.downloadBlob(sampleImage).then((res) => {
        const base64Data = res?.data?.data;
        if (!base64Data) {
          throw new Error("No data found to download");
        }
        // Convert base64 to a Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${sampleImage}.${extension}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
      });
    } catch (error) {
      console.error("Error downloading the file", error);
    }
  };


  const handleAddReportClick = () => {
    setShowModal(true); // Toggle between expanded and collapsed states
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaveDisabled(true);
    console.log("formData", formData);

    let validationErrors;
    if (id) {
      if (isFilterExpanded) {
        validationErrors = validateCreateReportFormForEdit(
          formData.reportID,
          formData.reportName,
          formData.reportDescription,
          formData.workspaceID,
          formData.productTableName,
          formData.productTableColumnName,
          formData.products,
          formData.productVisualName,
        );
      } else {
        validationErrors = validateCreateReportFormForEdit(
          formData.reportID,
          formData.reportName,
          formData.reportDescription,
          formData.workspaceID
        );
      }
    } else {
      if (isFilterExpanded) {
        validationErrors = validateCreateReportForm(
          formData.reportID,
          formData.reportName,
          formData.reportDescription,
          formData.workspaceID,
          image,
          formData.productTableName,
          formData.productTableColumnName,
          formData.products,
          formData.productVisualName,
        );
      } else {
        validationErrors = validateCreateReportForm(
          formData.reportID,
          formData.reportName,
          formData.reportDescription,
          formData.workspaceID,
          image
        );
      }
    }

    if (Object.keys(validationErrors).length > 0) {
      console.log("validationErrors", validationErrors);
      setErrors(validationErrors);
      setIsSaveDisabled(false);
    } else {
      setLoader(true);
      const requestBody = {
        ...formData,
        sampleImage: formData.sampleImage ? formData.sampleImage.name : "",
      };
      console.log("form submitted", requestBody);
      if (id) 
      {
        if (image) { 
          const file = image; // Get the selected file from input
          const formDataDoc = new FormData(); // Create new FormData object
          formDataDoc.append("file", file); // Append file to the form data
        
          // Upload the file (image)
          await researchDataService.uploadBlob(formDataDoc).then(async (res) => {
            console.log("res after upload blob", res);
        
            // Extract the file name from the response
            const uploadedFileName = res?.data?.data;  // The file name from the response
        
            // Log products array from formData before processing
            console.log("formData.products", formData.products);
        
            // Map productIds from products array
            const productIds = formData.products
              .map((product) => (product && product.id ? [product.id] : []))
              .flat();
        
            // Create the editRequestBody with the mapped productIds and additional fields
            let editRequestBody = {
              ...formData,
              sampleImage: uploadedFileName || "",
              products: (formData.products || []).filter((product) => product !== ""),
              productIds, // map product ids
            };
        
            // Log the editRequestBody before sending the update request
            console.log("editRequestBody", editRequestBody);
        
            // Update the report with the new data
            await ReportService.updateReport(editRequestBody)
              .then((res) => {
                console.log("res", res);
                toast.success("Success!", {
                  position: "top-right",
                  autoClose: 2000,
                });
                setTimeout(() => {
                  navigate("/ReportConfiguration"); // Navigate to the DataReport page after 3 seconds
                }, 2000);
              })
              .catch((error) => {
                toast.error(error?.response?.data || "Error Occurred", {
                  position: "top-right",
                  autoClose: 2000,
                });
              });
          });
        }
        else
        {
          console.log("formData.products", formData.products);
        
            // Map productIds from products array
            const productIds = formData.products
              .map((product) => (product && product.id ? [product.id] : []))
              .flat();
        
            // Create the editRequestBody with the mapped productIds and additional fields
            let editRequestBody = {
              ...formData,
              products: (formData.products || []).filter((product) => product !== ""),
              productIds, // map product ids
            };
        
            // Log the editRequestBody before sending the update request
            console.log("editRequestBody", editRequestBody);
        
            // Update the report with the new data
            await ReportService.updateReport(editRequestBody)
              .then((res) => {
                console.log("res", res);
                toast.success("Success!", {
                  position: "top-right",
                  autoClose: 2000,
                });
                setTimeout(() => {
                  navigate("/ReportConfiguration"); // Navigate to the DataReport page after 3 seconds
                }, 2000);
              })
              .catch((error) => {
                toast.error(error?.response?.data || "Error Occurred", {
                  position: "top-right",
                  autoClose: 2000,
                });
              });
          }     
      } else {
        console.log("im else");
        try {
          const file = image; // Get the selected file from input
          const formDataDoc = new FormData(); // Create new FormData object
          formDataDoc.append("file", file);

          await researchDataService
            .uploadBlob(formDataDoc)
            .then(async (res) => {
              console.log("res after upload blob", res);

              const requestBody1 = {
                ...formData,
                sampleImage: res?.data?.data ? res?.data?.data : "",
              };
              await ReportService.createReport(requestBody1)
                .then((res) => {
                  console.log("res", res);
                  toast.success("Success!", {
                    position: "top-right", // Use string directly if POSITION is undefined
                    autoClose: 2000, // duration in milliseconds
                  });
                  // window.location.href = "/ReportConfiguration";
                  setTimeout(() => {
                    navigate("/ReportConfiguration"); // Navigate to the DataReport page after 3 seconds
                  }, 2000);
                })
                .catch((error) => {
                  toast.error("Error occurred while saving!", {
                    position: "top-right", // Use string directly
                    autoClose: 2000,
                  });
                })
                .finally(() => {
                  setLoader(false);
                });
            });
        } catch (error) {}
      }
    }

    //  await ReportService.createReport()
  };
  const handleInputChange = (e) => {
    setIsSaveDisabled(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    const newErrors = {...errors};
    delete newErrors[name];
    setErrors(newErrors);
  };
  const handleSubmitProduct = async () => {
    setIsSaveDisabled(false);
    if (!validateProduct()) {
      return; // Stop submission if validation fails
    } else {
      const newProduct = {
        productName,
        productDescription,
        status: "Active", // Assuming default status is active
        createdBy: localStorage.getItem("email"), // You can dynamically set this
        createdAt: new Date().getTime(),
        updatedAt: new Date().getTime(),
        updatedBy: localStorage.getItem("email"), // You can dynamically set this
      };

      if (id) {
        try {
          const productExists = products.some(
            (product) => product.productName === newProduct.productName
          );
          if (!productExists) {
            const res = await productService.createProduct(newProduct);
            if (res) {
              // Return the updated product with the new id
              console.log("data", res.data);
              setProducts([...products, res.data]); // Add new product to the array
              setProductName("");
              setProductDescription("");
            }
          } else {
            toast.error("Filter already exists", {
              position: "top-right", // Use string directly
              autoClose: 2000,
            });
            setProductName("");
            setProductDescription("");
          }
        } catch (error) {
          toast.error("Error while adding filter. Please try again", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      } else {
        const productExists = products.some(
          (product) => product.productName === newProduct.productName
        );
        if (!productExists) {
          setProducts([...products, newProduct]); // Add new product to the array
          setProductName("");
          setProductDescription("");
        } else {
          toast.error("Filter already exists", {
            position: "top-right", // Use string directly
            autoClose: 2000,
          });
          setProductName("");
          setProductDescription("");
        }
      }
      setShowModal(false);
    }
  };
  const handleEditProduct = (index) => {
    setIsSaveDisabled(false);
    setShowModal(true);
    setEditingIndex(index); // Set the index of the product being edited
    setProductName(products[index].productName);
    setProductDescription(products[index].productDescription);
  };
  const handleDeleteProduct = (index) => {
    setIsSaveDisabled(false);
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };
  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, products: products }));
  }, [products]);

  return (
    <>
      {/* <LogoutAndRedirect /> */}
      <ToastContainer />
      <div className="content page-wrapper">
        <div className="page-content">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a
                  onClick={() => {
                    navigate({ pathname: "/ReportConfiguration" });
                  }}
                  className="fw-400"
                >
                  Report Configuration
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="fw-700 no-link"
                style={{ cursor: "unset" }}
              >
                <strong className="fw-500">{id ? formData.reportName : 'Add Report'}</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            <Col lg={12}>
              <Card className="outer-card">
                <Card.Header className="bg-white border-0 p-0">
                  <div class="d-flex align-items-center">
                    <div>
                      <h5 class="mb-0 fw-500">Report Configuration</h5>
                    </div>
                  </div>
                </Card.Header>
                <Card.Body className="px-2">
                  <Row>
                    <Col lg={12}>
                      <Form onSubmit={handleSubmit}>
                        <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                          <Row>
                            <Col lg={12}>
                              <h5
                                style={{
                                  fontSize: "14px",
                                  background: "#fff",
                                  padding: "0 0px",
                                }}
                                className="text-12 mb-4 overlay-title"
                              >
                                Report Details
                              </h5>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report Name
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="reportName"
                                  className="custom-field"
                                  value={formData.reportName}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.reportName}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.reportName}
                                </Form.Control.Feedback>
                                {/* {errors.reportName && (
                                  <Alert variant="danger">
                                    {errors.reportName}
                                  </Alert>
                                )} */}
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-3">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="reportID"
                                  className="custom-field"
                                  value={formData.reportID}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.reportID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.reportID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Workspace ID
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="workspaceID"
                                  className="custom-field"
                                  value={formData.workspaceID}
                                  onChange={handleInputChange}
                                  isInvalid={!!errors.workspaceID}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.workspaceID}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col lg={4} md={6}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Report Description
                                </Form.Label>
                                <Form.Group controlId="formTextarea">
                                  <Form.Control
                                    as="textarea"
                                    name="reportDescription"
                                    className="custom-field overflow-hidden"
                                    rows={5}
                                    placeholder=""
                                    value={formData.reportDescription}
                                    onChange={handleInputChange}
                                    isInvalid={!!errors.reportDescription}
                                  />
                                  <Form.Control.Feedback type="invalid">
                                    {errors.reportDescription}
                                  </Form.Control.Feedback>
                                </Form.Group>
                              </Form.Group>
                            </Col>

                            <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                              <Form.Group className="mb-2">
                                <Form.Label
                                  className="fw-500"
                                  style={{ fontSize: "14px" }}
                                >
                                  Upload Thumbnail
                                </Form.Label>
                                <Form.Control
                                  type="file"
                                  name="uploadDocument"
                                  className="custom-field"
                                  onChange={(e) => handleDrop(e)}
                                  isInvalid={!!errors?.image}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors?.image}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                            {id && (
                              <Col xxl={3} xl={4} lg={4} md={6} sm={12}>
                                <Form.Group className="mb-2">
                                  <Form.Label
                                    className="fw-500"
                                    style={{ fontSize: "14px" }}
                                  >
                                    Download Thumbnail
                                  </Form.Label>
                                  <Nav.Link
                                    onClick={(e) => {
                                      handleDownload(
                                        formData.sampleImage
                                      );
                                    }}
                                    variant="link"
                                    className="text-danger"
                                  >
                                    <span
                                      className="fa fa-download me-2"
                                      style={{
                                        // width: "14px",
                                        fontSize: "12px",
                                        // paddingLeft: "20px",
                                      }}
                                    />
                                  </Nav.Link>
                                </Form.Group>
                              </Col>
                            )}
                          </Row>
                        </div>
                        {/* <Row className="mt-4">
                          <Col
                            lg={isExpanded ? 12 : 3}
                            className={`add-product-btn ${
                              isExpanded ? "styled-btn" : ""
                            }`}
                          >
                            {!isExpanded && (
                              <div className="styled-border-btn">
                                <Button
                                  onClick={handleAddReportClick}
                                  className="btn-light"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i class="fa-solid fa-circle-plus"></i> Add
                                  Product
                                </Button>
                              </div>
                            )}
                          </Col>
                          {isExpanded && (
                            <Col
                              lg={isExpanded ? 12 : 6}
                              className={`add-product-form ${
                                isExpanded ? "" : "d-none"
                              }`}
                            >
                              <div className="form-inner position-relative p-3 mt-4 mb-4 pt-4 shadow-none">
                                <Row>
                                  <Col lg={12}>
                                    <h5
                                      style={{
                                        fontSize: "14px",
                                        background: "#fff",
                                        padding: "0 0px",
                                      }}
                                      className="text-12 mb-4 overlay-title"
                                    >
                                      Product Details
                                    </h5>
                                  </Col>

                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-3">
                                      <Form.Label
                                        className="fw-500"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Product Name
                                      </Form.Label>
                                      <Form.Control
                                        type="text"
                                        name="productName"
                                        value={productName}
                                        onChange={(e) =>
                                          setProductName(e.target.value)
                                        }
                                        className="custom-field"
                                        isInvalid={!!errors.productName}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.productName}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4} md={6}>
                                    <Form.Group className="mb-2">
                                      <Form.Label
                                        className="fw-500"
                                        style={{ fontSize: "14px" }}
                                      >
                                        Product Description
                                      </Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        className="custom-field overflow-hidden"
                                        rows={1}
                                        placeholder=""
                                        value={productDescription}
                                        onChange={(e) =>
                                          setProductDescription(e.target.value)
                                        }
                                        isInvalid={!!errors.productDescription}
                                      />
                                      <Form.Control.Feedback type="invalid">
                                        {errors.productDescription}
                                      </Form.Control.Feedback>
                                    </Form.Group>
                                  </Col>
                                  <Col lg={4}>
                                    <Button
                                      // type="submit"
                                      onClick={handleSubmitProduct}
                                      style={{ fontSize: "14px" }}
                                      className="btn"
                                    >
                                      Save
                                    </Button>
                                  </Col>
                                </Row>
                                <Row></Row>
                              </div>
                            </Col>
                          )}
                          <Col
                            lg={12}
                            className={`product-table ${
                              isExpanded || (products && products.length)
                                ? ""
                                : "d-none"
                            }`}
                          >
                            {products.length > 0 ? (
                              <Table bordered hover responsive className="mt-3">
                                <thead className="table light-thead">
                                  <tr>
                                    <th>Product</th>
                                    <th>Description</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {products.map((product, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Nav.Link className="text-decoration-underline">
                                          {product.productName}
                                        </Nav.Link>
                                      </td>
                                      <td>
                                        <p className="overflow-description lh-inherit">
                                          {product.productDescription}
                                        </p>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center">
                                          <Nav.Link
                                            variant="link"
                                            className="me-3"
                                            onClick={(e) => {
                                              handleEditProduct(index);
                                              handleDeleteProduct(index);
                                            }}
                                          >
                                            <Image
                                              style={{ width: "14px" }}
                                              src={editIcon}
                                            />
                                          </Nav.Link>

                                          <Nav.Link
                                            variant="link"
                                            className="text-danger"
                                            onClick={() =>
                                              handleDeleteProduct(index)
                                            }
                                          >
                                            <Image
                                              style={{ width: "14px" }}
                                              src={deleteIcon}
                                            />
                                          </Nav.Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col
                            lg={isExpanded ? 12 : 9}
                            className="add-product-footer-btn text-end"
                          >
                            <Button
                              className="btn-light me-2"
                              onClick={(e) => {
                                e.preventDefault();
                                setIsExpanded(false);
                                !id && setProducts([]);
                                navigate({
                                  pathname: "/ReportConfiguration",
                                });
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              onClick={handleSubmit}
                              style={{ fontSize: "14px" }}
                              className="btn"
                              disabled={isSaveDisabled || loader}
                            >
                              {loader && (
                                <Spinner
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                  animation="border"
                                  variant="danger"
                                  size="sm"
                                  className="me-2"
                                />
                              )}
                              Save
                            </Button>
                          </Col>
                        </Row> */}
                        <Row className="mt-4">
                          <Col>
                            <div className="styled-border-btn">
                              <Button
                                onClick={
                                  !isFilterExpanded
                                    ? handleAddFilterClick
                                    : handleCancelFilterClick
                                }
                                className="btn-light  mt-4"
                                style={{ fontSize: "14px" }}
                              >
                                <i
                                  className={`fa-solid ${
                                    isFilterExpanded
                                      ? "fa-circle-minus"
                                      : "fa-circle-plus"
                                  }`}
                                ></i>{" "}
                                {isFilterExpanded
                                  ? "Cancel Filter"
                                  : "Add Filter"}
                              </Button>
                            </div>
                          </Col>
                        </Row>

                        {isFilterExpanded && (
                          <Row>
                            <Col lg={12}>
                              <Card className="outer-card mt-3">
                                <Card.Header className="bg-white border-0 p-0">
                                  <div class="d-flex align-items-center">
                                    <div>
                                      <h5 class="mb-0 fw-500">
                                        Filter Configuration
                                      </h5>
                                    </div>
                                  </div>
                                </Card.Header>
                                <Card.Body className="px-2">
                                  <Row>
                                    <Col lg={12}>
                                      <div className="form-inner position-relative p-3 mt-3 pt-4 shadow-none">
                                        <Row className="align-items-center">
                                          <Col lg={12}>
                                            <h5
                                              style={{
                                                fontSize: "14px",
                                                background: "#fff",
                                                padding: "0 0px",
                                              }}
                                              className="text-12 mb-4 overlay-title"
                                            >
                                              Filter Details
                                            </h5>
                                          </Col>
                                          <Col lg={4} md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label
                                                className="fw-500"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Table Name
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="productTableName"
                                                className={`custom-field custom-group-field ${
                                                  edit
                                                    ? "editable-field"
                                                    : "non-editable-field"
                                                }`}
                                                value={
                                                  formData.productTableName
                                                }
                                                onChange={handleInputChange}
                                                isInvalid={
                                                  !!errors.productTableName
                                                }
                                                disabled={!edit}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.productTableName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={4} md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label
                                                className="fw-500"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Column Name
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="productTableColumnName"
                                                className={`custom-field custom-group-field ${
                                                  edit
                                                    ? "editable-field"
                                                    : "non-editable-field"
                                                }`}
                                                value={
                                                  formData.productTableColumnName
                                                }
                                                onChange={handleInputChange}
                                                isInvalid={
                                                  !!errors.productTableColumnName
                                                }
                                                disabled={!edit}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.productTableColumnName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={4} md={6}>
                                            <Form.Group className="mb-3">
                                              <Form.Label
                                                className="fw-500"
                                                style={{ fontSize: "14px" }}
                                              >
                                                Visual ID
                                              </Form.Label>
                                              <Form.Control
                                                type="text"
                                                name="productVisualName"
                                                className={`custom-field custom-group-field ${
                                                  edit
                                                    ? "editable-field"
                                                    : "non-editable-field"
                                                }`}
                                                value={
                                                  formData.productVisualName
                                                }
                                                onChange={handleInputChange}
                                                isInvalid={
                                                  !!errors.productVisualName
                                                }
                                                disabled={!edit}
                                              />
                                              <Form.Control.Feedback type="invalid">
                                                {errors.productVisualName}
                                              </Form.Control.Feedback>
                                            </Form.Group>
                                          </Col>
                                          <Col lg={4} className="mt-sm-3">
                                            {isSaved ? (
                                              <Button
                                                className="btn-light me-2"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setEdit(true);
                                                  setIsSaved(false);
                                                }}
                                              >
                                                <Image
                                                  style={{ width: "14px" }}
                                                  src={editIcon}
                                                  className="me-1"
                                                />
                                                Edit
                                              </Button>
                                            ) : (
                                              <>
                                                <Button
                                                  style={{ fontSize: "14px" }}
                                                  className="btn me-2"
                                                  onClick={handleSave}
                                                >
                                                  Save
                                                </Button>
                                              </>
                                            )}
                                            {edit && !isSaved && (
                                              <Button
                                                className="btn-light me-2"
                                                onClick={() => {
                                                  setEdit(false);
                                                  setIsSaved(true);
                                                }}
                                              >
                                                Cancel
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </div>

                                      <Row className="mt-4">
                                        <Col
                                          lg={isExpanded ? 12 : 3}
                                          className={`add-product-btn ${
                                            isExpanded ? "styled-btn" : ""
                                          }`}
                                        >
                                          {isFilterExpanded && (
                                            <>
                                              <div className="styled-border-btn">
                                                <Button
                                                  onClick={handleAddReportClick}
                                                  className="btn-light"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  <i className="fa-solid fa-circle-plus"></i>{" "}
                                                  Add
                                                </Button>
                                              </div>
                                              {errors.products && (
                                                <Form.Control.Feedback className="d-flex" type="invalid">
                                                  {errors.products}
                                                </Form.Control.Feedback>
                                              )}
                                            </>
                                          )}
                                        </Col>

                                        <Modal
                                          className="product-modal product-popup"
                                          show={showModal}
                                          onHide={handleCloseModal}
                                          centered
                                        >
                                          <Modal.Header className="pt-2 pb-2">
                                            Add Details
                                          </Modal.Header>
                                          <Modal.Body>
                                            <Row>
                                              <Col lg={12} md={12}>
                                                <Form.Group className="mb-3">
                                                  <Form.Label
                                                    className="fw-500"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    Filter Name
                                                  </Form.Label>
                                                  <Form.Control
                                                    type="text"
                                                    name="productName"
                                                    value={productName}
                                                    onChange={(e) =>
                                                      setProductName(
                                                        e.target.value
                                                      )
                                                    }
                                                    className="custom-field"
                                                    isInvalid={
                                                      !!errors.productName
                                                    }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.productName}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Col>
                                              <Col lg={12} md={12}>
                                                <Form.Group className="mb-2">
                                                  <Form.Label
                                                    className="fw-500"
                                                    style={{
                                                      fontSize: "14px",
                                                    }}
                                                  >
                                                    Filter Description
                                                  </Form.Label>
                                                  <Form.Control
                                                    as="textarea"
                                                    className="custom-field overflow-hidden"
                                                    rows={1}
                                                    placeholder=""
                                                    value={productDescription}
                                                    onChange={(e) =>
                                                      setProductDescription(
                                                        e.target.value
                                                      )
                                                    }
                                                    isInvalid={
                                                      !!errors.productDescription
                                                    }
                                                  />
                                                  <Form.Control.Feedback type="invalid">
                                                    {errors.productDescription}
                                                  </Form.Control.Feedback>
                                                </Form.Group>
                                              </Col>
                                              <Col lg={12}>
                                                <Button
                                                  // type="submit"
                                                  onClick={handleSubmitProduct}
                                                  style={{ fontSize: "14px" }}
                                                  className="btn"
                                                >
                                                  Save
                                                </Button>
                                              </Col>
                                            </Row>
                                          </Modal.Body>
                                        </Modal>
                                        <Col
                                          lg={12}
                                          className={`product-table ${
                                            isExpanded ||
                                            (products && products.length)
                                              ? ""
                                              : "d-none"
                                          }`}
                                        >
                                          {products.length > 0 ? (
                                            <>
                                              <Table
                                                bordered
                                                hover
                                                responsive
                                                className="mt-3"
                                              >
                                                <thead className="table light-thead">
                                                  <tr>
                                                    <th>Filter Name</th>
                                                    <th>Filter Description</th>
                                                    <th>Action</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {products.map(
                                                    (product, index) => (
                                                      <tr key={index}>
                                                        <td>
                                                          <Nav.Link className="text-decoration-underline">
                                                            {
                                                              product.productName
                                                            }
                                                          </Nav.Link>
                                                        </td>
                                                        <td>
                                                          <p className="overflow-description lh-inherit">
                                                            {
                                                              product.productDescription
                                                            }
                                                          </p>
                                                        </td>
                                                        <td>
                                                          <div className="d-flex align-items-center">
                                                            <Nav.Link
                                                              variant="link"
                                                              className="me-3"
                                                              onClick={(e) => {
                                                                handleEditProduct(
                                                                  index
                                                                );
                                                                handleDeleteProduct(
                                                                  index
                                                                );
                                                              }}
                                                            >
                                                              <Image
                                                                style={{
                                                                  width: "14px",
                                                                }}
                                                                src={editIcon}
                                                              />
                                                            </Nav.Link>

                                                            {/* Delete Button */}
                                                            <Nav.Link
                                                              variant="link"
                                                              className="text-danger"
                                                              onClick={() =>
                                                                handleDeleteProduct(
                                                                  index
                                                                )
                                                              }
                                                            >
                                                              <Image
                                                                style={{
                                                                  width: "14px",
                                                                }}
                                                                src={deleteIcon}
                                                              />
                                                            </Nav.Link>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    )
                                                  )}
                                                </tbody>
                                              </Table>

                                              {/* Add Button appears after the table */}
                                              {/* <div
                                                className="styled-border-btn"
                                                style={{ textAlign: "right" }}
                                              >
                                                <Button
                                                  onClick={
                                                    handleAddMoreReportClick
                                                  }
                                                  className="btn-light"
                                                  style={{ fontSize: "14px" }}
                                                >
                                                  <i className="fa-solid fa-circle-plus"></i>{" "}
                                                  Add More
                                                </Button>
                                              </div> */}
                                            </>
                                          ) : (
                                            <></> // If there are no products, nothing is shown
                                          )}
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>
                                </Card.Body>
                              </Card>
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </Col>
                    <Col
                      lg={isExpanded ? 12 : 9}
                      className="add-product-footer-btn text-end"
                    >
                      <div className="fixed-btn">
                        <Button
                          className="btn-light me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setIsExpanded(false);
                            !id && setProducts([]);
                            navigate({
                              pathname: "/ReportConfiguration",
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          onClick={handleSubmit}
                          style={{ fontSize: "14px" }}
                          className="btn"
                          disabled={error || loader}
                        >
                          {loader && (
                            <Spinner
                              style={{
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              animation="border"
                              variant="danger"
                              size="sm"
                              className="me-2"
                            />
                          )}
                          Save
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddReport;
