import React, { useEffect, useState } from "react";
import { Row, Col, Breadcrumb, Image, Card } from "react-bootstrap";
import researchDataService from "../../services/researchDataService";
import backIcon from "../../assets/backIcon.png";
import { useLocation, useParams } from "react-router-dom";

const DownloadAndView = () => {
  const { id } = useParams();
  const { state } = useLocation();
  const blobLocation = id;
  const [imageSrc, setImageSrc] = useState(null);
  const [pdfSrc, setPdfSrc] = useState(null);
  const [isPdf, setIsPdf] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    console.log(blobLocation);
    if (blobLocation === "NoPreviewFound") {
      setNotFound(true);
    } else {
      if (blobLocation.endsWith(".pdf")) {
        setIsPdf(true);
        handleViewPdf();
      } else {
        handleViewImage();
      }
    }
  }, []);

  const getLastElement = (str) => {
    const parts = str.split(".");
    return parts.pop();
  };

  const handleViewPdf = async () => {
    try {
      console.log("in view PDF", blobLocation);

      // Make an API call to download the file as a blob
      const res = await researchDataService.downloadBlob(blobLocation);
      const base64Data = res?.data?.data;

      if (!base64Data) {
        throw new Error("No data found to view");
      }

      // Convert base64 to a Blob with "application/pdf" MIME type
      const byteCharacters = atob(base64Data);
      const byteNumbers = Array.from(byteCharacters, (char) =>
        char.charCodeAt(0)
      );
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Create a URL for the blob to display as a PDF
      const pdfUrl = URL.createObjectURL(blob);
      setPdfSrc(pdfUrl);

      // Clean up the URL after the component unmounts
      return () => URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error viewing the PDF", error);
    }
  };

  const handleViewImage = async () => {
    try {
      console.log("in view image", blobLocation);
      // Make an API call to download the file as a blob
      await researchDataService.downloadBlob(blobLocation).then((res) => {
        const base64Data = res?.data?.data;
        if (!base64Data) {
          throw new Error("No data found to view");
        }

        // Convert base64 to a Blob
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, (char) =>
          char.charCodeAt(0)
        );
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray]);

        // Create a URL for the blob to display as an image
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);

        // Clean up the URL after the component unmounts
        return () => URL.revokeObjectURL(imageUrl);
      });
    } catch (error) {
      console.error("Error viewing the image", error);
    }
  };

  return (
    <>
      <div className="content page-wrapper pb-5">
        <div className="page-content">
          <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
            <Breadcrumb className="mb-0">
              <Breadcrumb.Item>
                <Image
                  className="me-1"
                  src={backIcon}
                  style={{
                    width: "16px",
                    cursor: "pointer",
                    verticalAlign: "text-bottom",
                  }}
                  onClick={() => window.history.back()}
                  alt="back"
                />{" "}
                <a
                  onClick={() => {
                    window.history.back();
                  }}
                  className="fw-400"
                >
                  View Report
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>{state?.title}</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <Row>
            {notFound ? (
              <Col lg={12} className="text-center">
                <Card
                  className="outer-card h-80"
                  style={{
                    background: "#F2F5F7",
                    display: "flex",
                    alignItsms: "center",
                    justifyContent: "center",
                  }}
                >
                  <p className="ms-3 fs-6 mt-3">No Preview Found</p>
                </Card>
              </Col>
            ) : (
              <Col lg={12}>
                {isPdf && pdfSrc && (
                  <iframe
                    src={pdfSrc}
                    title="PDF Document"
                    style={{ width: "100%", height: "500px" }}
                  />
                )}
                {!isPdf && imageSrc && (
                  <div className="h-80 p-2">
                    <img
                      src={imageSrc}
                      alt="Preview"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </Col>
            )}
          </Row>
        </div>
      </div>
    </>
  );
};

export default DownloadAndView;
