import { Outlet, Navigate } from 'react-router-dom';
import { decryptData } from "../../services/authService";

const PrivateRoutes = ({ role, isCommon }) => {
  const email = localStorage.getItem("email");
  const fetchedrole = localStorage.getItem("role");
  let decryptedRole = '';
  if (fetchedrole) {
    decryptedRole = decryptData(fetchedrole);
  }

  // return(
  //   email ? <Outlet/> : <Navigate to="/login"/>
  // );

  if (email) {
    if (role === decryptedRole || (role === 'ADMIN' && decryptedRole === 'SUPERADMIN') || isCommon) {
      return <Outlet />;
    } else {
      return <Navigate to="/restricted" />;
    }
  } else {
    return <Navigate to="/login" />;
  }
}

export default PrivateRoutes;