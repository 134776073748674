import React from "react";
import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Row,
  Col,
  Image,
  Nav,
  Form,
  Modal,
} from "react-bootstrap";
import "../../../src/dashboard.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import researchDataService from "../../services/researchDataService";
import sheetIcon from "../../assets/icons/sheet.png";


const CustomCardForSubscribedReport = ({
  subscribed,
  biId,
  id,
  blobLocation,
  title,
  imageSrc,
  previewSrc,
  description,
  previewAlt = "Click to Preview",
  buttonLabel,
  // downloadUrl, // Assuming the download URL comes from props
  products,
  productTableName,
  productTableColumnName,
  productTableFilterOperator,
  productVisualName,
  thumbnailBlobLocation,
  hasSubscribedProduct,
}) => {
  const navigate = useNavigate();
  const [showModal1, setShowModal1] = useState(false);
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [docUrl, setDocUrl] = useState('');
  const [isThumbnail, setIsThumbnail] = useState(false);
  const [showProductNotSelectedModal, setShowProductNotSelectedModal] = useState(false);
  console.log("blobLocation", blobLocation);

  const getLastElement = (str) => {
    const parts = str.split('.');
    return parts.pop();
  };

  useEffect(() => {
    if (thumbnailBlobLocation && thumbnailBlobLocation !== 'NoPreviewFound' && !docUrl) {
      setIsThumbnail(true);
      try {
        researchDataService.downloadBlob(thumbnailBlobLocation).then((res) => {
          const base64Data = res?.data?.data
          if (base64Data) {
            // Convert base64 to a Blob
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const docUrl = URL.createObjectURL(blob);
            setDocUrl(docUrl);
          }
        })
      } catch (error) {
        console.error(error);
      }
    } else if (blobLocation && blobLocation !== 'NoPreviewFound' && !docUrl) {
      try {
        researchDataService.downloadBlob(blobLocation).then((res) => {
          const base64Data = res?.data?.data
          if (base64Data) {
            // Convert base64 to a Blob
            const byteCharacters = atob(base64Data);
            const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray]);
            const docUrl = URL.createObjectURL(blob);
            setDocUrl(docUrl);
          }
        })
      } catch (error) {
        console.error(error);
      }
    }
  }, []);

  const downloadFile = async () => {
    try {
      await researchDataService.downloadBlob(blobLocation).then((res) => {
        const base64Data = res?.data?.data
        if (base64Data) {
          // Convert base64 to a Blob
          const byteCharacters = atob(base64Data);
          const byteNumbers = Array.from(byteCharacters, (char) => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray]);
          const docUrl = URL.createObjectURL(blob);
          setDocUrl(docUrl);
          const extension = getLastElement(blobLocation);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `downloadedFile.${extension}`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(link.href);
        } else {

        }
      })
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownload = async () => {
    // e.preventDefault();
    if (docUrl) {
      const extension = getLastElement(blobLocation);
      const link = document.createElement("a");
      link.href = docUrl;
      link.download = `downloadedFile.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    } else {
      downloadFile();
    }
  };

  const handleCheckboxChange = (product, checked) => {
    if (checked) {
      setSelectedProducts([...selectedProducts, product]);
    } else {
      setSelectedProducts(selectedProducts.filter((p) => p !== product));
    }
  };



  return (
    <Col lg={4} sm={6} className="d-flex mb-4">
      <Card className="custom-card inner-card w-100 position-relative">
        <Card.Body className="p-0" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <div className="card-header-section">
            <Card.Title className="card-title custom-card-title">
              {title}
            </Card.Title>
          </div>

          <div className="card-image m-0">
            <Image src={docUrl ? docUrl : imageSrc} alt="Report" className="image-hover" />
            <div className="preview-link">
              <Image
                src={previewSrc}
                alt={previewAlt}
                className="preview-image"
              />
              <Nav.Link
                onClick={(e) => {
                  e.preventDefault();
                  console.log("/ViewReport/" + blobLocation)
                  navigate({ pathname: "/ViewReport/" + blobLocation }, {
                    state: { title }
                  });
                }}
                className="text-danger p-0 fw-400"
              >
                {previewAlt}
              </Nav.Link>
            </div>
          </div>

          <Card.Text className="card-text">{description}</Card.Text>

          <div className="card-footer-section mx-auto d-sm-flex d-md-block d-block d-sm-block"
            style={{
              marginTop: 'auto',
            }}>
            <Button
              className="btn"
              onClick={(e) => {
                e.preventDefault();
                {
                  buttonLabel === "View" && hasSubscribedProduct
                    ? navigate("/BusinessReports/" + biId, {
                      state: { productTableName, productTableColumnName, productTableFilterOperator, products, title, productVisualName }
                    })
                    : buttonLabel === "View" && !hasSubscribedProduct
                      ? setShowProductNotSelectedModal(true)
                      : handleDownload()
                }
              }}
              style={{
                fontSize: "14",
                paddingLeft: "30px",
                paddingRight: "30px",
              }}
            >
              {buttonLabel}
            </Button>
            {products && products.length > 0 && (
              <Button
                className="btn btn-secondary ml-2"
                onClick={() => setShowModal1(true)}
                style={{
                  margin: "2px",
                  fontSize: "14",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }}
              >
                Filters
              </Button>
            )}
          </div>
          <div
            style={{
              justifyContent: "center !important",
              alignContent: "center",
              alignItems: "center",
              top: "145px",
              right: "5px",
              position: "absolute",
            }}
          >
            {subscribed && (
              <Card.Text
                className="card-text-sub"
                style={{ justifyContent: "center" }}
              >
                <span
                  style={{
                    background: "green",
                    width: "16px",
                    height: "16px",
                    borderRadius: "50%",
                    color: "#fff",
                    lineHeight: "16px",
                    textAlign: "center",
                  }}
                  className="fa fa-check me-1"
                ></span>
                Subscribed
              </Card.Text>
            )}
          </div>

        </Card.Body>
      </Card>

      <Modal
        className="product-modal product-popup"
        show={showProductNotSelectedModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowProductNotSelectedModal(false)}
      >
        <Modal.Header className="border-0 m-auto pb-0">
          <Modal.Title>
            <h1 className="text-12 mb-0 text-center">Filter not selected</h1>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "35px" }}>
          <div className="text-center mb-3">
            <Image
              src={sheetIcon}
              height={54}
              alt="Check mailbox icon"
            />
          </div>
          <h5 className="text-2 text-center mb-3">
            It looks like you haven’t subscribed to any filter for this report.
            Please choose and subscribe to a filter to view the report.
          </h5>
          <div className="d-grid mt-4 gap-3">
            <Button
              onClick={() => {
                setShowProductNotSelectedModal(false);
                navigate({ pathname: '/Dashboards'});
              }}
              className="btn btn-secondary btn-lg fs-14"
            >
              Explore Subscriptions
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="product-modal product-popup"
        show={showModal1}
        // size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowModal1(false)}
      >
        <Modal.Header className="pt-2 pb-2">Subscribed Filters</Modal.Header>
        <Modal.Body className="ps-1 pe-2 pt-2 pb-2 overflow-hidden">
          <Form>
            <div className="product-list">
              {products?.map((product, index) => (
                <>
                  <p className="cus-form-check-lable">{product.productName}</p>
                </>
              ))}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default CustomCardForSubscribedReport;
