import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import CustomCardForSunscribedReport from "../components/ui/CustomCardForSunscribedReport";
import reportImage from "../assets/dc-report.png";
import preview from "../assets/icons/view.png";
import "../dashboard.css";
import userService from "../services/userService";
import reportService from "../services/reportService";
import subscriptionService from "../services/subscriptionService";
import { useNavigate } from "react-router-dom";
import countryImage from "../assets/country-fact-sheet.png";
import businessImage from "../assets/business-analitics.png";
import tdmImage from "../assets/TDM.png";
import LogoutAndRedirect from "./LogoutAndRedirect";
import cardLoader from "../assets/loading-chain.gif";

const MySubscription = () => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [researchData, setResearchData] = useState([]);
  const [sortOrder, setSortOrder] = useState("a-z"); // For sorting selection
  const [allReports, setAllReports] = useState([]);

  useEffect(() => {
    fetchData();
    fetchReports();
  }, []);

  useEffect(() => {
    // Only proceed when both dependencies are loaded
    if (allReports.length && cardData.length) {
      const updatedCards = cardData.map((card) => {
        const report = allReports.find((report) => report.id === card.biId);
  
        if (report) {
          const hasSubscribedProduct =
            (report.productIds.length && card.products.length) ||
            (!report.productIds.length && !card.products.length);
  
          return { ...card, hasSubscribedProduct };
        }
  
        return { ...card, hasSubscribedProduct: false };
      });
  
      // Only update if there's a meaningful difference
      setCardData((prev) =>
        JSON.stringify(prev) !== JSON.stringify(updatedCards) ? updatedCards : prev
      );
    }
  }, [allReports, cardData]); // Dependencies: allReports and cardData

  const fetchData = async () => {
    const email = localStorage.getItem("email");
    await userService.getUserByEmail(email).then(async (res) => {
      let userId = res.data.id;
      try {
        const apiResponse = await subscriptionService
          .getSubscriptionByUserId(res.data.id)
          .then((apiResponse) => {
            if (
              apiResponse.statusCode === 200 &&
              apiResponse?.data?.subscriptionId !== 0
            ) {
              const mappedCardData = apiResponse?.data?.reports?.map(
                (report) => ({
                  products: report.product,
                  biId: report.id,
                  blobLocation: report.sampleImage,
                  id: apiResponse.data.subscriptionId,
                  title: report.reportName,
                  imageSrc: cardLoader,
                  // report.reportName.trim() === `Country Fact Sheet`
                  //   ? countryImage
                  //   : report.reportName.trim() ===
                  //     "Dubai Chambers Members' Exports"
                  //   ? businessImage
                  //   : report.reportName.trim() ===
                  //     "Trade Data Monitoring Report"
                  //   ? tdmImage
                  //   : reportImage,
                  previewSrc: preview,
                  description: `${report.reportDescription}. `,
                  buttonLink: `/ViewReport/${report.id}`,
                  createdAt: report.createdAt, // Assuming you have createdAt in your data
                  productTableName: report.productTableName,
                  productTableColumnName: report.productTableColumnName,
                  productTableFilterOperator: report.productTableFilterOperator,
                  productVisualName: report.productVisualName,
                })
              );

              const mappedResearchData = apiResponse?.data?.researchData?.map(
                (item) => ({
                  id: item.id,
                  blobLocation: item.blobLocation,
                  title: item.fileName,
                  imageSrc: cardLoader,
                  // item?.fileName?.trim() === `Country Fact Sheet`
                  //   ? countryImage
                  //   : item?.fileName?.trim() ===
                  //     "Dubai Chambers Members' Exports"
                  //   ? businessImage
                  //   : item?.fileName?.trim() ===
                  //     "Trade Data Monitoring Report"
                  //   ? tdmImage
                  //   : reportImage,
                  previewSrc: preview,
                  description: `${item.fileDescription}.`,
                  buttonLink: `/ViewResearchData/${item.id}`,
                  createdAt: item.createdAt, // Assuming you have createdAt in your data
                  thumbnailBlobLocation: item.thumbnailBlobLocation,
                })
              );
              console.log("mappedCardData", mappedCardData);
              setCardData((prev) => mappedCardData);
              setResearchData((prev) => mappedResearchData);
            } else {
              navigate("/subscription");
            }
          });
      } catch (error) {
        console.error("Error fetching subscriptions:", error);
      }
    });
  };

  const fetchReports = async () => {
    try {
      let reportList = await reportService.listReports();
      setAllReports(reportList?.data);
      console.log({ reportList });
    } catch (error) {
      console.log(error);
    }
  };

  // Function to handle sorting
  const handleSortChange = (value) => {
    setSortOrder(value);
  };

  // Function to sort card data
  const sortCards = (cards) => {
    switch (sortOrder) {
      case "a-z":
        return cards.sort((a, b) => a.title.localeCompare(b.title));
      case "z-a":
        return cards.sort((a, b) => b.title.localeCompare(a.title));
      case "latest":
        return cards.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
      case "oldest":
        return cards.sort(
          (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
        );
      default:
        return cards;
    }
  };

  return (
    <div className="content page-wrapper">
      {/* <LogoutAndRedirect /> */}
      <div className="page-content">
        <Row>
          <Col lg={12}>
            <Card className="outer-card">
              {/* Dropdown for sorting */}

              {/* Business Reports Section */}
              <Row>
                <Col lg={10} md={10}>
                  <div className="card-title-danger d-flex align-items-center mb-4">
                    <h3 className="outer-card-title">Business Report</h3>
                    {/* <span>
                      Your Subscription plan will be valid for{" "}
                      <strong className="fw-500">3 months.</strong>
                    </span> */}
                  </div>
                </Col>
                <Col lg={2} md={2} sm={4}>
                  <Form.Select
                    aria-label="Sort by"
                    value={sortOrder}
                    onChange={(e) => handleSortChange(e.target.value)}
                    className="mb-3 form-search-field select-dropdown form-select"
                    style={{ paddingLeft: "10px" }}
                  >
                    <option value="" disabled>
                      Sort By
                    </option>
                    <option value="latest">Latest First</option>
                    <option value="oldest">Oldest First</option>
                    <option value="a-z">A-Z</option>
                    <option value="z-a">Z-A</option>
                  </Form.Select>
                </Col>
              </Row>

              <Row>
                {sortCards(cardData).map((card, index) => (
                  <CustomCardForSunscribedReport
                    key={index}
                    {...card}
                    buttonLabel={"View"}
                  />
                ))}
              </Row>

              {/* Research Data Section */}
              {researchData && researchData.length !== 0 && (
                <>
                  {" "}
                  <Row>
                    <Col lg={12}>
                      <div className="card-title-danger d-flex align-items-center mb-4 mt-3">
                        <h3 className="outer-card-title">Research Data</h3>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {sortCards(researchData).map((card, index) => (
                      <CustomCardForSunscribedReport
                        key={index}
                        {...card}
                        buttonLabel={"Download"}
                      />
                    ))}
                  </Row>
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MySubscription;
