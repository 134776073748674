import React, { useEffect, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../dashboard.css";
import { Row, Form, Breadcrumb, Image, NavLink, Nav } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import backIcon from "../assets/icons/backIcon.png";
import { useLocation } from "react-router-dom";

const PowerbiReport = ({
  embedData,
  productTableName,
  productTableColumnName,
  productTableFilterOperator,
  products,
  productVisualName,
}) => {
  const { state } = useLocation();
  const [embedConfig, setEmbedConfig] = useState(null);
  const [reportInstance, setReportInstance] = useState(null); // Store the embedded report instance

  useEffect(() => {
    if (
      embedData &&
      embedData.embedReports &&
      embedData.embedReports.length > 0
    ) {
      const report = embedData.embedReports[0]; // Accessing the first report in the response

      // Set up the configuration with the data from embedData
      setEmbedConfig({
        type: "report",
        id: report.reportId, // reportId from embedData
        embedUrl: report.embedUrl, // embedUrl from embedData
        accessToken: embedData.embedToken, // embedToken from embedData
        tokenType: models.TokenType.Embed,
        settings: {
          filterPaneEnabled: false, // Set to true to enable the filter pane
          navContentPaneEnabled: false, // Set to true to enable the navigation pane
        },
      });
    }
  }, [embedData]);

  // Apply report-level filter
  const applyReportFilter = async (report) => {
    const filter = {
      $schema: "http://powerbi.com/product/schema#basic",
      target: {
        table: productTableName,
        column: productTableColumnName,
      },
      operator: productTableFilterOperator,
      values: products && products.length ? products.map((product) => product.productName) : '',
    };

    try {
      await report.setFilters([filter]);
      console.log("Report-level filter applied");
    } catch (error) {
      console.error("Error applying report filter:", error);
    }
  };

  // Apply visual-level filter
  // const applyVisualFilter = async (report) => {
  //   let visualName = 'fe113142d6b876d19241';
  //   try {
  //     const pages = await report.getPages();
  //     // Retrieve the page that contain the visual. For the sample report it will be the active page
  //     let page = pages.filter(function (page) {
  //       return page.isActive
  //     })[0];
  //     const visuals = await page.getVisuals();
  //     console.log({visuals});
  //     const filteredVisuals = visuals.filter((visual) => visual.title === productTableColumnName && visual.type === 'slicer');
  //     console.log({filteredVisuals});
  //     if (filteredVisuals && filteredVisuals.length) {
  //       visualName = filteredVisuals[0].name;
  //     }
  //   }
  //   catch (errors) {
  //     console.log(errors);
  //   }
  //   const filter = {
  //     $schema: "http://powerbi.com/product/schema#advanced",
  //     target: {
  //       table: productTableName,
  //       column: productTableColumnName,
  //     },
  //     filterType: models.FilterType.Advanced,
  //     logicalOperator: 'And',
  //     conditions: [
  //       { operator: "Is", value: products && products[0] && products[0].productName },
  //     ],
  //   };

  //   try {
  //     const pages = await report.getPages();
  //     const activePage = pages.find((page) => page.isActive);

  //     if (activePage) {
  //       const visuals = await activePage.getVisuals();
  //       // const targetVisual = visuals.find(
  //       //   (visual) => visual.name === visualName
  //       // );
  //       console.log({visuals})
  //       let slicer = visuals.filter(function (visual) {
  //         return visual.type === "slicer" && visual.name === visualName;
  //       })[0];
  //       console.log({slicer});
  //       await slicer.setSlicerState({ filters: [filter] });

  //       // if (targetVisual) {
  //       //   await targetVisual.updateFilters(models.FiltersOperations.Add, [
  //       //     filter,
  //       //   ]);
  //       //   console.log("Visual-level filter applied");
  //       // } else {
  //       //   console.log("Target visual not found.");
  //       // }
  //     }
  //   } catch (error) {
  //     console.error("Error applying visual filter:", error);
  //   }
  // };

  const applyVisualFilter = async (report) => {
    try {
      let visualName = '';
      const pages = await report.getPages();
      // Retrieve the page that contain the visual. For the sample report it will be the active page
      let page = pages.filter(function (page) {
        return page.isActive
      })[0];
      const visuals = await page.getVisuals();
      console.log({ visuals });
      const filteredVisuals = visuals.filter((visual) => visual.title === productVisualName && visual.type === 'slicer');
      console.log({ filteredVisuals });
      if (filteredVisuals && filteredVisuals.length) {
        visualName = filteredVisuals[0].title;
      }

      const filter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: productTableName,
          column: productTableColumnName,
        },
        filterType: models.FilterType.Advanced,
        logicalOperator: 'And',
        conditions: [
          { operator: "Is", value: products && products[0] ? products[0].productName : '' },
        ],
      };

      const activePage = pages.find((page) => page.isActive);
      if (activePage) {
        const visuals = await activePage.getVisuals();
        console.log({ visuals })
        let slicer = visuals.filter(function (visual) {
          return visual.type === "slicer" && visual.title === visualName;
        })[0];
        console.log({ slicer });
        await slicer.setSlicerState({ filters: [filter] });
      }
    }
    catch (errors) {
      console.log(errors);
    }
  };

  if (!embedConfig) {
    return <div>Loading Power BI report...</div>;
  }

  return (
    <div className="content page-wrapper">
      <div className="page-content">
        <div class="page-breadcrumb  d-sm-flex align-items-center mb-3">
          <Breadcrumb className="mb-0">
            <Breadcrumb.Item>
              <Image
                className="me-1"
                src={backIcon}
                style={{
                  width: "16px",
                  cursor: "pointer",
                  verticalAlign: "text-bottom",
                }}
                onClick={() => window.history.back()}
                alt="back"
              />{" "}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  window.history.back();
                }}
                className="fw-400"
              >Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item
              className="fw-700 no-link"
              style={{ cursor: "unset" }}
            >
              <strong className="fw-500">{state?.title}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Row>
          <Col lg={12}>
            <div style={{ height: "100vh", width: "100%" }}>
              <PowerBIEmbed
                embedConfig={embedConfig}
                cssClassName={"report-style-class"}
                eventHandlers={
                  new Map([
                    [
                      "loaded",
                      async function (event, report) {
                        console.log("Report loaded");
                        setReportInstance(report);
                        // Apply report-level filters after loading
                        await applyReportFilter(report);
                        // Apply visual-level filters
                        if (products && products.length) {
                          await applyVisualFilter(report);
                        }
                      },
                    ],
                    [
                      "rendered",
                      function () {
                        console.log("Report rendered");
                      },
                    ],
                    [
                      "error",
                      function (event) {
                        console.log("Report error", event.detail);
                      },
                    ],
                  ])
                }
                getEmbeddedComponent={(embeddedReport) => {
                  console.log("Embedded Report:", embeddedReport);
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PowerbiReport;
