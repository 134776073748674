import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";

import Home from "./pages/Home";
import HomeTwo from "./pages/HomeTwo";
import HomeThree from "./pages/HomeThree";
import About from "./pages/About";
import Register from "./pages/Register";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";
import Contact from "./pages/Contact";
import Dashboard from "./pages/Dashboard";
import Resubscribe from "./pages/Resubscribe";
import Header from "./components/ui/Header/Header";
import Sidebar from "./components/ui/Sidebar/Sidebar";
import MySubscription from "./pages/MySubscription";
import ViewReport from "./pages/ViewReport";
import AccountProfile from "./pages/AccountProfile";
import ManageUser from "./pages/ManageUser";
import ViewManageUser from "./pages/ViewManageUser";
import Settings from "./pages/Settings";
import ReportConfiguration from "./pages/ReportConfiguration";
import AddReport from "./pages/AddReport";
import DataReport from "./pages/DataReport";
import AddDataReport from "./pages/AddDataReport";
import BiIntegration from "./pages/BiIntegration";
import ManageAdmin from "./pages/ManageAdmin";
import AddAdmin from "./pages/AddAdmin";
import AdminProfile from "./pages/AdminProfile";
import PowerbiReport from "./pages/PowerbiReport";
import ViewPowerBIDash from "./pages/ViewPowerBIDash";
import "./App.css";
import { decryptData } from "./services/authService";
import userService from "./services/userService";
import subscriptionService from "./services/subscriptionService";
import Feedback from "./pages/Feedback";
import LogoutAndRedirect from "./pages/LogoutAndRedirect";
import PrivateRoutes from "./components/routing/PrivateRoute";
import PageNotFound from "./components/ui/PageNotFound";
import DownloadAndView from "./components/ui/DownloadAndView";
import EmailVerify from "./pages/EmailVerify";

function DashboardLayout({ children, prop, header }) {
  // console.log("prop layout", prop)
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className={` ${!isSidebarOpen ? "layout-wrapper-off" : ""}`}>
      {/* <LogoutAndRedirect /> */}
      <Header prop={prop} header={header}>
        <Button
          onClick={toggleSidebar}
          variant=""
          className="flex-start toggle-menu-btn me-2"
        >
          <i class="fa-solid fa-arrow-left" style={{ color: "#fff" }}></i>
        </Button>
      </Header>
      <Sidebar prop={prop}></Sidebar>
      <div className="content">{children}</div>
    </div>
  );
}

// function ProtectedRoute({ children, allowedRoles }) {
//   const [role, setRole] = useState(null);

//   useEffect(() => {
//     const storedRole = getRole();
//     setRole(storedRole);
//   }, []);
//   const getRole = async () => {
//     return await decryptData(localStorage.getItem("role"));
//   };

//   console.log("rolee", localStorage.getItem("role"));
//   if (role?.toLowerCase() && allowedRoles?.toLowerCase().includes(role)) {
//     return children;
//   }
//   // If the role is not allowed, redirect to home or login
//   return <Navigate to="/login" />;
// }

function App() {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState(false);
  useEffect(() => {
    fetchUser();
  }, []);

  console.log("subscription", subscription);

  const fetchUser = async () => {
    const email = localStorage.getItem("email");
    if (email) {
      await userService
        .getUserByEmail(email)
        .then(async (res) => {
          // console.log("user", res.data.id);
          if (res && res.data && res.data.id) {
            await subscriptionService
              .getSubscriptionByUserId(res.data.id)
              .then((res) => {
                // console.log("res", res);
                if (res?.data?.subscriptionId !== 0) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                } else if (
                  res.data.reports !== null &&
                  res.data.researchData !== null
                ) {
                  // console.log(
                  //   "res.data.subscriptionId",
                  //   res.data.subscriptionId
                  // );
                  setSubscription(true);
                }
              })
              .catch((error) => {});
          }
        })
        .catch((error) => {});
    }
  };

  return (
    <div className="App">
      <LogoutAndRedirect />
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/HomeTwo" element={<HomeTwo />} />
          <Route path="/HomeThree" element={<HomeThree />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route
            path="/ResetPassword/verify-link"
            element={<ResetPassword />}
          />
          <Route path="/Contact" element={<Contact />} />
          <Route element={<PrivateRoutes role={'MEMBER'} isCommon={window.location.pathname === '/AccountProfile' ? true : false} />} >
            <Route
              path="/Dashboards"
              element={
                <DashboardLayout
                  prop={subscription}
                  header={"Explore Subscriptions"}
                >
                  {subscription ? <Resubscribe /> : <Dashboard />}
                </DashboardLayout>
              }
            />

            <Route
              path="/all"
              element={
                <DashboardLayout
                  prop={subscription}
                  header={"Explore Subscriptions"}
                >
                  <Dashboard />
                </DashboardLayout>
              }
            />

            <Route
              path="/subscription"
              element={
                <DashboardLayout
                  prop={subscription}
                  header={"Explore Subscriptions"}
                >
                  <Dashboard />
                </DashboardLayout>
              }
            />
            <Route
              path="/MySubscription"
              element={
                <DashboardLayout
                  prop={subscription}
                  header={"My Subscriptions"}
                >
                  <MySubscription />
                </DashboardLayout>
              }
            />
            <Route
              path="/ViewReport/:id"
              element={
                <DashboardLayout header={"View Report"}>
                  <DownloadAndView />
                </DashboardLayout>
              }
            />
            <Route
              path="/AccountProfile"
              element={
                <DashboardLayout header={"My Profile"}>
                  <AccountProfile />
                </DashboardLayout>
              }
            />
            <Route
              path="/BusinessReports/:id"
              element={
                <DashboardLayout header={"Business Reports"}>
                  <ViewPowerBIDash />
                </DashboardLayout>
              }
            />
            <Route
              path="/Feedback"
              element={
                <DashboardLayout header={"Feedback"}>
                  <Feedback />
                </DashboardLayout>
              }
            />
            <Route
              path="/Settings"
              element={
                <DashboardLayout header={"Settings"}>
                  <Settings />
                </DashboardLayout>
              }
            />
          </Route>
          <Route element={<PrivateRoutes role={'ADMIN'} />} >
            <Route
              path="/ReportConfiguration"
              element={
                <DashboardLayout header={"Report Configuration"}>
                  <ReportConfiguration />
                </DashboardLayout>
              }
            />
            <Route
              path="/AddReport"
              element={
                <DashboardLayout header={"Add Report"}>
                  <AddReport />
                </DashboardLayout>
              }
            />

            <Route
              path="/AddReport/:id"
              element={
                <DashboardLayout header={"Add Report"}>
                  <AddReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/AddReport/:id"
              element={
                <DashboardLayout header={"Add Report"}>
                  <AddReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/DataReport"
              element={
                <DashboardLayout header={"Research Data"}>
                  <DataReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/DataReport/:id"
              element={
                <DashboardLayout header={"Research Data"}>
                  <DataReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/AddDataReport"
              element={
                <DashboardLayout header={"Add Research Data"}>
                  <AddDataReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/AddDataReport/:id"
              element={
                <DashboardLayout header={"Add Research Data"}>
                  <AddDataReport />
                </DashboardLayout>
              }
            />
            <Route
              path="/BiIntegration"
              element={
                <DashboardLayout header={"BI Integration"}>
                  <BiIntegration />
                </DashboardLayout>
              }
            />
            <Route
              path="/ManageAdmin"
              element={
                <DashboardLayout header={"Manage Admins"}>
                  <ManageAdmin />
                </DashboardLayout>
              }
            />
            <Route
              path="/AddAdmin"
              element={
                <DashboardLayout header={"Add Admin"}>
                  <AddAdmin />
                </DashboardLayout>
              }
            />
            <Route
              path="/ManageUser"
              element={
                <DashboardLayout header={"Manage User"}>
                  <ManageUser />
                </DashboardLayout>
              }
            />
            <Route
              path="/ViewManageUser"
              element={
                <DashboardLayout header={"View Manage User"}>
                  <ViewManageUser />
                </DashboardLayout>
              }
            />
            <Route
              path="/AdminProfile"
              element={
                <DashboardLayout header={"Admin Profile"}>
                  <AdminProfile />
                </DashboardLayout>
              }
            />
            <Route
              path="/AccountProfile"
              element={
                <DashboardLayout header={"My Profile"}>
                  <AccountProfile />
                </DashboardLayout>
              }
            />
          </Route>
          <Route path="/restricted" element={<PageNotFound />} />
          <Route path="/EmailVerification/link" element={<EmailVerify />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>

      {/* <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/ResetPassword/:emailId" element={<ResetPassword />} />
          <Route path="/Contact" element={<Contact />} />
 
         
          <Route
            path="/Dashboards"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN", "SUPERADMIN"]}>
                <DashboardLayout>
                  <Dashboard />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/MySubscription"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <MySubscription />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/ViewReport"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <ViewReport />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/AccountProfile"
            element={
              <ProtectedRoute allowedRoles={["MEMBER", "ADMIN"]}>
                <DashboardLayout>
                  <AccountProfile />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
         
          <Route
            path="/Settings"
            element={
              <ProtectedRoute allowedRoles={["ADMIN"]}>
                <DashboardLayout>
                  <Settings />
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router> */}
    </div>
  );
}

export default App;
